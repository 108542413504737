<template>
    <!-- 奖状照片 -->
     <div style="margin-left: -20px;width: 90%;margin-top: 20px;">
        <el-row>
            <el-col :span="24" >
                <el-image style="width: 100%; height: 40%" :src="MainAward" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row :gutter="30">
            <el-col :span="8" v-for="item in awards" :key="item.value" >
                <el-image style="height: 250px;"  :src="item.value" fit="fill"></el-image>

            </el-col>
        </el-row>
     </div>
</template>
<script>
export default {
    name: 'NationalAward',
    data() {
        return {
            MainAward:"../../static/image/awards/8.jpg",
            awards:[
            {value:"../../static/image/awards/3.jpg"},
                {value:"../../static/image/awards/4.jpg"},
                {value:"../../static/image/awards/2.jpg"},
                {value:"../../static/image/awards/1.jpg"},
                {value:"../../static/image/awards/6.jpg"},
                {value:"../../static/image/awards/9.jpg"},
                {value:"../../static/image/awards/10.jpg"},
                {value:"../../static/image/awards/16.jpg"},
                {value:"../../static/image/awards/18.jpg"},
                {value:"../../static/image/awards/19.jpg"},
                {value:"../../static/image/awards/20.jpg"},
                {value:"../../static/image/awards/21.jpg"},
                {value:"../../static/image/awards/5.jpg"},
                // {value:"../../static/image/awards/7.jpg"},
                // {value:"../../static/image/awards/11.jpg"},
                // {value:"../../static/image/awards/12.jpg"},
                // {value:"../../static/image/awards/13.jpg"},
                // {value:"../../static/image/awards/14.jpg"},
                // {value:"../../static/image/awards/15.jpg"},
                // {value:"../../static/image/awards/17.jpg"},


            ]
        }
    },
    methods: {
    }
}
</script>
<style scoped>
</style>