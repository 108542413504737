<template>
  <div class="direction1">
      <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
      <div class="line"></div>
      <!-- 标题块  -->
      <div class="nameBox">
          <div class="numBox">
              <h3>1</h3>
          </div>
          <div class="titleBox">
              <h3 style="position: relative;left: 23px;">植物智慧医学</h3>
          </div>
      </div>
      <!-- 内容块 -->
      <div>
          <div class="contentBox">
              <p>作物病害是农业生产面临的主要挑战之一，错误的诊断结果以及随意使用农药将加重病害的威胁以致更大经济损失，现有人工评估极难准确得到病害种类和病害等级，进而影响植物病害的治疗。数字化和智能化是解决病虫害防治的有力武器。目前实验室从“数据构建-感知算法-用药决策”三方面着手，构建作物病害多模态智能诊断技术解决上述问题。
              </p>
              <p>人工智能技术已广泛应用于不同学科领域，尤其在智慧农业方面。然而，在AI应用于垂直领域时，数据的收集与处理是首要问题。目前全球病害数字化诊断数据分散、信息注释不足且数据多样性不够，限制了多学科交叉研究的发展。实验室构建了跨领域多学科的数据集成方法，支撑智慧植保学科的数字化发展。其中一项重要工作是构建了一个名为植物表型组疾病分析 (Plant Phenomics Analysis of Disease, PlantPAD) 的数据集被全球65个国家近7000多人使用，有效解决了当前在病害数字化领域面临的多学科数据壁垒问题。
              </p>
              <p>作物病害数字化诊断数据存在样本量不平衡、内容差异大及多模态感知困难等问题。实验室提出了针对不同农业环境的作物病害多模态感知与精准治疗决策算法，从而提升病害数字化诊断的准确性。
              </p>
              <p>现有病害数字化诊断存在无法解决田间病害的早期检测、多病害识别、病害的精准用药等问题，实验室集成了数字化病害诊断和用药平台，解决病害数字化智能诊断到治疗决策的一体化技术。
              </p>
          </div>

          <el-image class="imageBox" style="width: 90%;" src="../../static/image/PlantHealth.png" fit="cover" />
      </div>


  </div>

</template>



<script>
export default {
  data() {
      return {
          TileName: 'Research Direction',
          researchItems: [
              { id: 1, title: "植物智慧医学" }
          ]
      }
  }
}
</script>
<style scoped>
.imageBox{
  position: relative;
  top: 65px;
  left: 5%;
}
.contentBox {
  position: relative;
  top: 60px;

}

.contentBox p {
  font-weight: 500;
  text-align: left;
  text-indent: 2em;
  padding: 0px;
  margin: 0px;
  line-height: 30px;
}

.nameBox {
  display: flex;
  position: relative;
  top: 49px;

}

.direction1 {
  width: 90%;
}

.line {
  position: relative;
  top: 30px;
  bottom: 32.5px;
  border: 0.1px solid #BCBCBC;
  width: 100%;
}

.numBox {
  width: 67px;
  height: 67px;
  background-color: #D4DFEB;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #2448FF;

}

.titleBox {
  height: 67px;
  width: 100%;
  font-size: 17px;
  color: rgb(45, 55, 90);
  background-color: #F7F9FA;
  justify-content: center;
  align-items: center;

}
</style>