<template>
  <div class="page-container">
    <!-- 顶部导航 -->
    <el-header class="header">
      <div class="logo"><img src="../../static/image/logo.png" class="logo_img">
        <div class="logo_text">实验室课题组</div>
      </div>
      <div class="nav-box">
        <el-menu :default-active="activeMenu" class="nav-menu" mode="horizontal" router>
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="/ResearchDirection">研究方向</el-menu-item>
          <el-menu-item index="/Members">人员组成</el-menu-item>
          <el-menu-item index="/PaperPatent">成果发表</el-menu-item>
          <el-menu-item index="/Projects">开源服务</el-menu-item>
          <el-menu-item index="/Awards">获奖展示</el-menu-item>
          <el-menu-item index="/Blogrolls">友情链接</el-menu-item>
        </el-menu>
      </div>

    </el-header>


    <!-- Banner 区域 -->
    <section class="banner">
      <h1>药物设计和计算生物学课题组</h1>
      <p>药物设计和计算生物学课题组</p>

    </section>

    <!-- 欢迎部分 -->
    <section class="welcome-section">
      <div class="research-directions">
        <h3>本课题组主要研究方向包括</h3>
        <ul>
          <div class="image-text-container"><img src="../../static/image/01.png">
            <div class="text">(1).大数据与人工智能基础研究;</div>
          </div>
          <div class="image-text-container"><img src="../../static/image/02.png">
            <div class="text">(2).植物智慧医学;</div>
          </div>
          <div class="image-text-container"><img src="../../static/image/03.png">
            <div class="text">(3).人工智能分子设计;</div>
          </div>
          <div class="image-text-container"><img src="../../static/image/05.png">
            <div class="text">(4).农业大数据智能应用;</div>
          </div>
        </ul>
      </div>

      <el-card class="welcome-card">
        <h2>欢迎访问王崎老师课题组网站</h2>
        <p>题组致力于药物设计、计算生物学和大数据分析领域的研究本课题组致力于药物设计、计算生物学和大数据分析领域的研究本课题组致力于药物设计、计算生物学和大数据分析领域的研究</p>
        <div class="stats grid-container">
          <div class="stat grid-item">
            <h3>15</h3>
            <p>科研项目</p>
          </div>
          <div class="stat grid-item">
            <h3>100+</h3>
            <p>发表论文数量</p>
          </div>
          <div class="stat grid-item">
            <h3>50</h3>
            <p>论文专利数</p>
          </div>
          <div class="stat grid-item">
            <h3>5</h3>
            <p>课题人数</p>
          </div>
        </div>
      </el-card>
    </section>


    <!-- 加入我们 -->
    <section class="join-us">
      <div class="content-with-image">
        <div class="text-content">
          <h1>加入我们</h1>
          <h2>JOIN US</h2>
          <p>热烈欢迎对药物设计、计算生物学、分子模拟及化学信息学感兴趣的本科生、研究生、博士后加入王崎教授课题组。</p>
          <router-link to="/Learnmore" class="learn-more-button">了解更多 >></router-link>
        </div>
        <div class="image-content">
          <img src="../../static/image/banner 2.png" alt="相关图片描述">
        </div>
      </div>
    </section>

    <!-- 联系信息 -->
    <section class="contact-info">
      <div class="contact-detailscontainer">
        <div class="contact-details">
          <h1>联系信息</h1>
          <h2>CONTACT INFORMATION</h2>
          <ul>
            <div class="contact-item"><img src="../../static/image/iconlianxiren.png">
              <div class="contact-text">联系人：王崎</div>
            </div>
            <div class="contact-item"><img src="../../static/image/icondizhi.png">
              <div class="contact-text">实验室地址：贵州大学崇厚楼9楼</div>
            </div>

            <div class="contact-item"><img src="../../static/image/iconyouxiang.png">
              <div class="contact-text">邮箱：wangqi@zju.edu.cn</div>
            </div>
          </ul>
        </div>
        <div class="map-image">
          <img src="../../static/image/map.png" alt="相关图片描述">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: "1",
    };
  },
};
</script>

<style scoped>
.page-container {
  font-family: Arial, sans-serif;
  color: #333;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  background-color: rgba(255, 255, 255, 0);
  /* 半透明背景 */
  position: absolute;
  /* 使导航栏固定在顶部 */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  /* 确保在图片之上 */
  border: none;
  box-shadow: none;
  outline: none;
}

.logo {
  padding-left: 10%;
  display: flex;
  align-items: center;
  /* 垂直居中 */
}

.logo_text {
  margin-left: 28px;
  /* 调整此值以增加或减少图片和文字之间的距离 */
  font-family: STHeiti;
  font-size: 25px;
  font-weight: bold;

}

/* .nav-menu {
  flex: 1;
  text-align: left;
  align-items: flex-end;
  background-color: rgba(241, 231, 231, 0);
  /* 半透明背景 */
  /* border: none;
  box-shadow: none;
  outline: none; */
  /* 去掉菜单的边框 */



/* } */
.nav-menu {
    flex: 1;
    text-align: right;
    align-items: flex-end;
    background-color: rgb(0 0 0 / 0%);
    /* 半透明背景 */
    border: none;
    /* 去掉菜单的边框 */

}


.nav-box {
  margin-right: 200px;
  font-size: 50xp;
  border: 0px;
}

.nav-menu .el-menu-item {
  background-color: rgb(0 0 0 / 0%);
  /* 菜单项背景透明 */
  color: black;
  /* 文字颜色，根据背景调整 */
  border: 0px;
}

.nav-menu .el-menu-item.is-active {
  color: #2448FF;
  /* 激活项颜色 */
  background-color: rgb(0 0 0 / 0%);
  /* 菜单项背景透明 */
  border: 0px;
}

.banner {
  /* text-align: center; */
  background-image: url("../../static/image/首页banner@2x.png");
  background-size: cover;
  /* 让背景图片覆盖整个元素 */
  background-position: center;
  padding: 305px;
  padding-left: 15%;

}

.container {
  display: flex;
  flex-direction: column;
  min-height: 10vh;
}

.welcome-section {
  /* display: flex; */
  position: relative;

  justify-content: space-around;
  padding: 0 15%;
  background-color: #4765fe;
  height: 550px;
  width: 770px;
  margin: 35px auto;

}

.image-text-container {
  display: flex;
  align-items: center;
  /* 垂直居中 */
}

.text {
  margin-left: 28px;
  /* 调整此值以增加或减少图片和文字之间的距离 */
}

.welcome-card {
  position: absolute;
  width: 600px;
  height: 650px;
  top: -150px;
  left: -1.2px;
}

.welcome-card h2 {
  align-items: center;

  /* text-align: center; */
}

.stats grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 每行显示两个列 */
  gap: 1rem;
  /* 列之间的间距 */
}

.stat {
  text-align: center;
}

.research-directions {
  background-color: #4765fe;
  color: #eee;
  position: absolute;
  width: 500px;
  left: 800px;
  border: 0px;
  margin-left: -200px;
  bottom: 50px;
}

.research-directions h3 {
  font-size: 25px;
  margin-left: 35px;

}

.research-directions ul {
  line-height: 5.5;
  margin-left: 35px;
}

.stats {
  display: flex;
  justify-content: space-between;
}

.stat {
  text-align: center;
}

.join-us {
  text-align: center;
  margin: 40px 0;
  text-align: left;
  padding-left: 15%;

}

.join-us h1 {
  font-size: 35px;
  margin-top: 70px;
}

.join-us h2 {
  font-size: 80px;
  margin-top: 70px;
  opacity: 0.1;
  padding-left: 15%;
}

.join-us p {
  margin-top: 80px;
  margin-bottom: 40px;
}

.content-with-image {
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
}

.text-content {
  flex: 1;
  /* 文字内容区域占据剩余空间 */
}

.image-content {
  margin-right: 270px;
  /* 图片与文字之间的间距 */
  margin-top: 20px;
}

.image-content img {
  max-width: 90%;
  /* 图片最大宽度为父元素宽度 */
  height: auto;
  /* 保持图片原始比例 */
}

.learn-more-button {
  margin-top: 20px;
}

.contact-detailscontainer {
  display: flex;
  justify-content: space-between;

  text-align: left;
  padding-left: 15%;
  background-color: #fafafa;
  padding: 20px;
}

.contact-details {
  padding-left: 13%;
}

.contact-info h1 {
  background-color: #fafafa;
  padding: 20px;
  font-size: 35px;
  margin-top: 70px;

}

.contact-info h2 {
  background-color: #fafafa;
  padding: 20px;
  font-size: 40px;
  margin-top: 40px;
  opacity: 0.1;


}

.contact-item {
  display: flex;
  align-items: center;
  padding: 30px;

}

.contact-text {
  margin-left: 28px;
}


.map-image img {
  margin-right: 150px;
  /* 图片与文字之间的间距 */
  margin-top: 10px;
  width: 800px;
  /* 调整图片的宽度 */
  height: 700px;
  /* 调整图片的高度 */
}

.banner h1,
p {
  text-align: left;
  padding-right: 30%;
  /* margin-left: 15%; */
}

.banner h1 {
  font-size: 3rem;
  color: #4765fe
}

.banner p {
  font-size: 1.9rem;
}


.grid-container {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  /* 2 列，每列等宽 */
  grid-template-rows: repeat(2, 1fr);
  /* 2 行，每行等高 */
  gap: 0px;
  /* 网格项之间的间隔 */
  width: 100%;
  /* 宽度可以根据需要调整 */
  max-width: 600px;
  /* 最大宽度 */
  background-color: #fff;
  /* padding: 10px; */
  box-sizing: border-box;


}

.grid-item {
  /* height: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.grid-item h3 {
  color: #4765fe;
}
</style>
