<template>
    <div class="teacher">
        <!-- 头像和基本信息 -->
        <div class="teacher-baseinfo">
            <div>
                <el-image style="height: 200px; width: 166px;" :src="imageUrl"></el-image>
            </div>
            <div class="teacher-baseinfo-infobox">
                <div class="title-box2">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                </div>
                <!-- 基本信息 -->
                <div class="teacher-baseinfo-content">
                    <ul>
                        <li v-for="item in basicInfo" :key="item.id" class="infos">
                            <span>{{ item.label }}</span>
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 个人简介 -->
        <div class="profile-box">
            <p>{{ profile }}</p>
        </div>
        <!-- 学习工作经历 -->
        <div class="experience-box">
            <div class="experience-infobox">
                <div class="title-box2">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">学习工作经历</h2>
                </div>
                <ul>
                    <li v-for="item in experiment" :key="item.id" class="infos">
                        <span>{{ item.label }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 代表论文 -->
        <div class="paper-box">
            <div class="experience-infobox">
                <div class="title-box2">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                </div>
                <ul style="list-style-type: disc;">
                    <li v-for="item in papers" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 科研项目 -->
        <!-- <div class="project-box">
            <div class="experience-infobox">
                <div class="title-box">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">科研项目</h2>
                </div>
                <ol >
                    <li v-for="item in project" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div> -->
        <!-- 重要奖项 -->
        <!-- <div class="award-box">
            <div class="experience-infobox">
                <div class="title-box">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
                </div>
                <ol >
                    <li v-for="item in award" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    name: 'TeacherChenPanFeng',
    data() {
        return {
            imageUrl: '../../static/image/teacher/ChenPanFeng.png',
            // 信息数据
            basicInfo: [
                { label: "单位和职称：", value: "校聘副教授，硕导" },
                { label: "办公地址：", value: "贵州省贵阳市花溪区贵州大学西区崇厚楼713-1室" },
                { label: "主要研究方向：", value: "人工智能、计算机视觉、智慧农业、知识图谱" },
                { label: "电子邮箱：", value: "pfchen@gzu.edu.cn" },
            ],
            experiment: [
                { label: "2022.03 ~ 现在：", value: "贵州大学，工作" },
                { label: "2017.09 ~ 2021.12：", value: "贵州大学 软件工程 博士"},
                { label: "2015.10 ~ 2017.08：", value: "黔南州中级人民法院 网络工程师"},
                { label: "2012.05 ~ 2015.09：", value: "黔南州罗甸县教育局 教研员"},
                { label: "2008.09 ~ 2011.06：", value: "华中师范大学 教育技术学 硕士"},
                { label: "2003.09 ~ 2007.06：", value: "三峡大学 教育技术学  学士 "},

            ],
            papers: [
                { value: "Chen, Panfeng, et al. Qlogice: Quantum logic empowered embedding for knowledge graph completion. Knowledge-Based Systems 239 (2022): 107963." },
                { value: "Chen, Panfeng, et al. Hamming distance encoding multihop relation knowledge graph completion. IEEE Access 8 (2020): 117146-117158." },
                { value: "Dong, Xinyu, et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science.Nucleic Acids Research 52.D1 (2024): D1556-D1568." },
                { value: "Zhao, Fuyong and Li, Yuyang and Wang, Yanhao and Li, Hui and Chen, Mei and Chen, Panfeng and Sun,, et al. Pulsar Candidate Classification with Multimodal Large Language Models. Neurips 2024 Workshop Foundation Models for Science: Progress, Opportunities, and Challenges." },

                
            ],
            
            profile: "研究方向为知识表示与推理，聚焦于知识增强的大模型研究，致力于用知识图谱缓解大模型的幻觉问题，交叉方向为植物病虫害精准防治。以第一作者或合作者公开发表SCI论文10余篇，参与国家重点研发、国家自然科学基金、贵州省重点研发项目4项，主持贵州省科技厅项目1项。"
        }
    }
}
</script>

<style>
.award-box{
    position: relative;
    top: 20px;
}
.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    text-indent: 2em;
    line-height: 25px
}

.teacher-baseinfo-content {
    width: 100%;
}

.infos {
    margin-top: 10px;
}

ul {
    list-style-type: none;
}

.title-box2 {
    display: flex;
    width: 100%;
}

.teacher-baseinfo-infobox {
    position: relative;
    left: 33px;
}

.teacher-baseinfo {
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.teacher {
    width: 89%;
}
</style>