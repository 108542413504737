<template>
    <div>
        <!-- 合作组 -->
        <div class="experience-box">
            <div class="experience-infobox">

                <ul style="list-style-type: none;">
                    <li v-for="item in cooperations" :key="itemIndex" class="infos">
                        <span style="font-size: 17px;">{{ item }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            cooperations: [
                "陈使明(华中科技大学)",
                "陈伟玲(中央电教馆)",
                "岑海艳(浙江大学)",
                "柴云鹏(中国人民大学)",
                "郭颖(北方工业大学)",
                "郭威(东京大学)",
                "刘武(中国科技大学)",
                "刘永进(清华大学)",
                "罗杰波(罗切斯特大学)",
                "雷亮(广东工业大学)",
                "李晖(贵州大学)",
                "李直旭(中国人民大学)",
                "陆雪松(华东师范大学)",
                "金时超(南京农业大学)",
                "金剑(普渡大学)",
                "王晓(武汉科技大学)",
                "王以松(贵州大学)",
                "王喜宾(贵州理工学院)",
                "易冉(上海交通大学)",
                "余婧雯(天津大学)",
                "姚小军(澳门理工大学)",
                "周济(英国剑桥作物中心)",
                "Luc Cleasen(哈瑟尔特大学)",
            ]
        }
    }
}
</script>
<style scoped>
.infos {
    margin-top: 6px;
    
}
</style>