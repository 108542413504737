<template>
    <div class="container">
        <el-row :gutter="0">
            <el-col :span="8" v-for="item in students" :key="i">
                <div class="infoBox">
                    <!-- 头像 -->
                    <!-- <el-avatar style="" shape="circle" :size="120" :src="item.imgurl" fit="cover"></el-avatar> -->
                    <el-image :src="item.imgurl" :fit="fits" class="avatar-box" ></el-image>
                    <!-- 基础信息 -->
                    <div class="infos">
                        <p style="font-weight: bold; font-size: 18px;">{{ item.name }}</p>
                        <p style="margin-top: -5px; font-size: 15px;">{{ item.major }}</p>
                        <p style="margin-top: -5px; font-size: 15px;">{{ item.country }}</p>
                    </div>
                </div>

            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fits: "cover",
            squareUrl:
                "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            students: [
                {
                    imgurl: "static/image/students/international/Deshani.jpg",
                    name: "Deshani",
                    major: "Big data for smart agriculture",
                    country: "Sri Lanka",

                },
                {
                    imgurl: "static/image/students/international/Shamika.jpg",
                    name: "Shamika",
                    major: "Big data for plant phenomics",
                    country: "Sri Lanka",

                },
            ]
        };
    },
}
</script>
<style scoped>
.container {
    margin-left: -20px;
}

.infos {
    margin-left: 12px;
    margin-top: 5px;
}

.infoBox {
    margin-top: 20px;
    display: flex;
}
.avatar-box{
    height: 130px;
    width: 130px;
    border-radius: 50%;
}
</style>