import { render, staticRenderFns } from "./CooperativeGroup.vue?vue&type=template&id=d4665884&scoped=true"
import script from "./CooperativeGroup.vue?vue&type=script&lang=js"
export * from "./CooperativeGroup.vue?vue&type=script&lang=js"
import style0 from "./CooperativeGroup.vue?vue&type=style&index=0&id=d4665884&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4665884",
  null
  
)

export default component.exports