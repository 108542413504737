import { render, staticRenderFns } from "./NationalAward.vue?vue&type=template&id=562e01b4&scoped=true"
import script from "./NationalAward.vue?vue&type=script&lang=js"
export * from "./NationalAward.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "562e01b4",
  null
  
)

export default component.exports