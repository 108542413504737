var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('el-header',{staticClass:"header"},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"logo_img",attrs:{"src":require("../../static/image/logo.png")}}),_c('div',{staticClass:"logo_text"},[_vm._v("实验室课题组")])]),_c('div',{staticClass:"nav-box"},[_c('el-menu',{staticClass:"nav-menu",attrs:{"default-active":_vm.activeMenu,"mode":"horizontal","router":""}},[_c('el-menu-item',{attrs:{"index":"1"}},[_vm._v("首页")]),_c('el-menu-item',{attrs:{"index":"/ResearchDirection"}},[_vm._v("研究方向")]),_c('el-menu-item',{attrs:{"index":"/Members"}},[_vm._v("人员组成")]),_c('el-menu-item',{attrs:{"index":"/PaperPatent"}},[_vm._v("成果发表")]),_c('el-menu-item',{attrs:{"index":"/Projects"}},[_vm._v("开源服务")]),_c('el-menu-item',{attrs:{"index":"/Awards"}},[_vm._v("获奖展示")]),_c('el-menu-item',{attrs:{"index":"/Blogrolls"}},[_vm._v("友情链接")])],1)],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('div',{staticClass:"title1"},[_c('h3',[_vm._v("欢迎大家的加入")])]),_c('div',{staticClass:"contact-info1"},[_c('h2',[_vm._v("感兴趣请联系邮箱：wangqi@zju.edu.cn")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"join-us"},[_c('div',{staticClass:"content-with-image"},[_c('div',{staticClass:"text-content"},[_c('h1',[_vm._v("1, 招聘全职老师")]),_c('p',[_vm._v("我们渴望遇见那些心怀梦想、志存高远的教育者。您不仅是一位学者，更是一位导师，引领学生在知识的海洋中遨游，激发他们的好奇心与创造力。您的加入将为我们的团队注入新的活力，共同打造一个充满激情与创新的学习环境。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"join-us"},[_c('div',{staticClass:"content-with-image"},[_c('div',{staticClass:"text-content"},[_c('h1',[_vm._v("2, 招聘博士后或访问学者")]),_c('p',[_vm._v("对于那些已经在学术领域取得一定成就的研究者，我们欢迎您加入我们的研究团队。在这里，您将有机会深入探索前沿课题，与国内外顶尖学者交流合作，共同推动科学研究的发展。我们提供开放的研究平台和丰富的资源支持，期待与您共创辉煌。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"join-us"},[_c('div',{staticClass:"content-with-image"},[_c('div',{staticClass:"text-content"},[_c('h1',[_vm._v("3, 招收博士生或联培生")]),_c('p',[_vm._v("如果您对科研充满热情，渴望在学术道路上走得更远，那么我们欢迎您加入我们的博士生或联合培养项目。在这里，您将接受系统的科研训练，参与到高水平的科研项目中，与导师和团队成员一起攻克难关，实现个人的学术梦想。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"join-us"},[_c('div',{staticClass:"content-with-image"},[_c('div',{staticClass:"text-content"},[_c('h1',[_vm._v(" 4, 招收硕士生")]),_c('p',[_vm._v("对于那些对科研抱有浓厚兴趣的硕士生，我们为您提供一个良好的学习和发展平台。在这里，您将有机会参与到实际的科研项目中，与团队成员共同合作，提升自己的研究能力和实践技能。我们鼓励创新思维，支持每位学生在学术道路上不断前行。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"join-us"},[_c('div',{staticClass:"content-with-image"},[_c('div',{staticClass:"text-content"},[_c('h1',[_vm._v("5, 招收本科生")]),_c('p',[_vm._v("如果您是一名本科生，对科学研究充满好奇和热情，欢迎加入我们的团队。在这里，您将获得宝贵的科研经验，参与到实际项目中，与导师和团队成员密切合作。我们相信，每一个热爱科学的年轻人，都有机会在这里找到属于自己的舞台。")])])])])
}]

export { render, staticRenderFns }