<template>
    <div class="container">
        <el-row :gutter="0">
            <el-col :span="8" v-for="item in students" :key="i">
                <div class="infoBox">
                    <!-- 头像 -->
                    <!-- <el-avatar style="" shape="circle" :size="120" :src="item.imgurl" fit="cover"></el-avatar> -->
                    <el-image :src="item.imgurl" :fit="fits" class="avatar-box" ></el-image>
                    <!-- 基础信息 -->
                    <div class="infos">
                        <p style="font-weight: bold; font-size: 18px;">{{ item.name }}</p>
                        <p style="margin-top: -5px; font-size: 15px;">{{ item.major }}</p>
                        <p style="margin-top: -5px; font-size: 15px;">计算机科学与技术</p>
                    </div>
                </div>

            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fits: "cover",
            squareUrl:
                "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            students: [
                {
                    imgurl: "../../static/image/students/master/裴之蕈.jpg",
                    name: "裴之蕈",
                    major: "光谱重建",

                },
                {
                    imgurl: "static/image/students/master/陈国军.jpg",
                    name: "陈国军",
                    major: "知识图谱",

                },
                {
                    imgurl: "static/image/students/master/李青雷.jpg",
                    name: "李青雷",
                    major: "图像离散表征，推理加速",

                },
                {
                    imgurl: "static/image/students/master/黄杨.jpg",
                    name: "黄杨",
                    major: "农业多光谱计算机视觉",

                },
                {
                    imgurl: "static/image/students/master/张天瀚.jpg",
                    name: "张天瀚",
                    major: "三维目标检测",

                },
                {
                    imgurl: "static/image/students/master/李耀羲.jpg",
                    name: "李耀羲",
                    major: "目标计数",

                },
                {
                    imgurl: "static/image/students/master/赵克君.jpg",
                    name: "赵克君",
                    major: "多模态",

                },
                {
                    imgurl: "static/image/students/master/张家伟.jpg",
                    name: "张家伟",
                    major: "视觉、语言模态融合",

                },
                {
                    imgurl: "static/image/students/master/王玉祥.jpg",
                    name: "王玉祥",
                    major: "少样本学习、细粒度学习",

                },
                {
                    imgurl: "static/image/students/master/淡玉娇.jpg",
                    name: "淡玉娇",
                    major: "图像分割",

                },
                {
                    imgurl: "static/image/students/master/邓星志.jpg",
                    name: "邓星志",
                    major: "知识图谱",

                },
                {
                    imgurl: "static/image/students/master/黄前顶.jpg",
                    name: "黄前顶",
                    major: "知识蒸馏",

                },
                {
                    imgurl: "static/image/students/master/周勇君.jpg",
                    name: "周勇君",
                    major: "小样本图像分类",

                },
                {
                    imgurl: "static/image/students/master/刘阳.jpg",
                    name: "刘阳",
                    major: "长尾病害一体化识别防控",

                },
                {
                    imgurl: "static/image/students/master/余娅.jpg",
                    name: "余娅",
                    major: "植物病害严重程度评估",

                },
                {
                    imgurl: "static/image/students/master/袁志豪.jpg",
                    name: "袁志豪",
                    major: "病害分级",

                },
                {
                    imgurl: "static/image/students/master/万潇阳.jpg",
                    name: "万潇阳",
                    major: "密集目标检测",

                },
                {
                    imgurl: "static/image/students/master/谢雨珊.jpg",
                    name: "谢雨珊",
                    major: "图像描述",

                },
                {
                    imgurl: "static/image/students/master/陈潮杰.jpg",
                    name: "陈潮杰",
                    major: "基础视觉模型",

                },
                {
                    imgurl: "static/image/students/master/周泽权.jpg",
                    name: "周泽权",
                    major: "小目标检测",

                },
                {
                    imgurl: "static/image/students/master/王可为.jpg",
                    name: "王可为",
                    major: "轻量化",

                },

                {
                    imgurl: "static/image/students/master/毛可欣.jpg",
                    name: "毛可欣",
                    major: "密集目标检测",

                },

                {
                    imgurl: "static/image/students/master/刘鸿鹏.jpg",
                    name: "刘鸿鹏",
                    major: "光谱重建",

                },

                {
                    imgurl: "static/image/students/master/顾航.jpg",
                    name: "顾航",
                    major: "知识图谱",

                },
                {
                    imgurl: "static/image/students/master/肖明淦.jpg",
                    name: "肖明淦",
                    major: "知识图谱与大模型相关",

                },
                {
                    imgurl: "static/image/students/master/张艺伦.jpg",
                    name: "张艺伦",
                    major: "酶功能预测",

                },
                {
                    imgurl: "static/image/students/master/孙天一.jpg",
                    name: "孙天一",
                    major: "image caption",

                },
                {
                    imgurl: "static/image/students/master/杨廷玮.jpg",
                    name: "杨廷玮",
                    major: "图文一致性描述",

                },
                {
                    imgurl: "static/image/students/master/秦路富.jpg",
                    name: "秦路富",
                    major: "多模态轻量化",

                },
                {
                    imgurl: "static/image/students/master/周军.jpg",
                    name: "周军",
                    major: "蛋白质功能预测",

                },
                {
                    imgurl: "static/image/students/master/张军.jpg",
                    name: "张军",
                    major: "蛋白质相互作用预测",

                },
                {
                    imgurl: "static/image/students/master/吴南宛.jpg",
                    name: "吴南宛",
                    major: "靶标的相互运用预测",

                },
                {
                    imgurl: "static/image/students/master/郑家豪.jpg",
                    name: "郑家豪",
                    major: "3D目标检测",

                },
                {
                    imgurl: "static/image/students/master/赵罗浪.jpg",
                    name: "赵罗浪",
                    major: "细粒度小样本",

                },
                {
                    imgurl: "static/image/students/master/王岚颖.jpg",
                    name: "王岚颖",
                    major: "视觉计数",

                },

                {
                    imgurl: "static/image/students/master/王修宁.jpg",
                    name: "王修宁",
                    major: "关系抽取结合大模型",

                },
                {
                    imgurl: "static/image/students/master/吴庆龙.jpg",
                    name: "吴庆龙",
                    major: "多模态分割",

                },
                {
                    imgurl: "static/image/students/master/王欢.jpg",
                    name: "王欢",
                    major: "跨域小样本目标检测",

                },
                {
                    imgurl: "static/image/students/master/肖甫.jpg",
                    name: "肖甫",
                    major: "计算机基础视觉",

                },

                {
                    imgurl: "static/image/students/master/尚兴存.jpg",
                    name: "尚兴存",
                    major: "模型的轻量化",

                },
                {
                    imgurl: "static/image/students/master/王囡屹.jpg",
                    name: "王囡屹",
                    major: "基础计算机视觉",

                },
                {
                    imgurl: "static/image/students/master/万巧玲.jpg",
                    name: "万巧玲",
                    major: "多模态图片生成",

                },






            ]
        };
    },
}
</script>
<style scoped>
.container {
    margin-left: -20px;
}

.infos {
    margin-left: 12px;
    margin-top: 5px;
}

.infoBox {
    margin-top: 20px;
    display: flex;
}
.avatar-box{
    height: 130px;
    width: 130px;
    border-radius: 50%;
}
</style>