<template>
    <div class="direction1">
        <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
        <div class="line"></div>
        <!-- 标题块  -->
        <div class="nameBox">
            <div class="numBox">
                <h3>1</h3>
            </div>
            <div class="titleBox">
                <h3 style="position: relative;left: 23px;">大数据与人工智能基础研究</h3>
            </div>
        </div>
        <!-- 内容块 -->
        <div>
            <div class="contentBox">
                <p>大数据与人工智能基础研究是一个充满活力的跨学科领域，通过将视觉大模型、注意力机制等前沿技术与计算机视觉深度融合，致力于解决传统计算机视觉中准确率不稳定、泛化能力弱、实时性差等关键挑战。这一研究方向在图像分析与理解、目标检测与识别等多个计算机视觉应用场景中都展现出强大的潜力，正在重塑计算机视觉领域的应用模式。
                </p>
                <p>在图像分析与理解方面，大数据与人工智能技术为复杂图像的处理提供了全新的解决方案。视觉大模型的出现，极大地提升了计算机对图像的理解能力。通过在大规模图像数据集上进行训练，视觉大模型能够自动学习到丰富的图像特征和语义信息，从而对各种复杂场景下的图像进行准确分析和理解。同时，注意力机制的引入，使得计算机在处理图像时能够更加关注关键区域，提高了图像分析的效率和准确性。例如，在图像分类任务中，注意力机制可以让模型更加关注图像中的关键特征，从而提高分类的准确率。
                </p>
                <p>目标检测与识别领域同样展现出巨大的发展潜力。基于深度学习的目标检测算法在大数据的支持下不断改进和优化，能够快速准确地检测出图像中的多个目标，并对其进行精确识别。这些算法在交通监控、智能物流等领域得到了广泛应用。此外，多模态数据融合技术在目标检测与识别中也发挥着重要作用。通过将图像数据与其他模态的数据，如激光雷达数据、红外数据等进行融合，能够提高目标检测与识别的准确率和可靠性，尤其是在复杂环境和恶劣条件下，多模态数据融合技术具有独特的优势。
                </p>
                <p>未来，随着大数据的不断积累、计算能力的持续提升以及算法的不断创新，大数据与人工智能基础研究在计算机视觉领域将发挥更加重要的作用。这一技术将推动智能安防、智能交通、智能医疗、智能制造等领域的快速发展，为人们的生活和工作带来更多的便利和创新。
                </p>
            </div>

            <el-image class="imageBox" style="width: 90%;" src="../../static/image/AiAndBigdatapng.png" fit="cover" />
        </div>


    </div>

</template>



<script>
export default {
    data() {
        return {
            TileName: 'Research Direction',
            researchItems: [
                { id: 1, title: "大数据与人工智能基础研究" }
            ]
        }
    }
}
</script>
<style scoped>
.imageBox{
    position: relative;
    top: 60px;
    left: 5%;
}
.contentBox {
    position: relative;
    top: 60px;

}

.contentBox p {
    font-weight: 500;
    text-align: left;
    text-indent: 2em;
    padding: 0px;
    margin: 0px;
    line-height: 30px;

}

.nameBox {
    display: flex;
    position: relative;
    top: 49px;

}

.direction1 {
    width: 90%;
}

.line {
    position: relative;
    top: 30px;
    bottom: 32.5px;
    border: 0.1px solid #BCBCBC;
    width: 100%;
}

.numBox {
    width: 67px;
    height: 67px;
    background-color: #D4DFEB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #2448FF;

}

.titleBox {
    height: 67px;
    width: 100%;
    font-size: 17px;
    color: rgb(45, 55, 90);
    background-color: #F7F9FA;
    justify-content: center;
    align-items: center;

}
</style>