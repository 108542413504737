<template>
    <div class="container">
        <el-row :gutter="0">
            <el-col :span="8" v-for="item in students" :key="i">
                <div class="infoBox">
                    <!-- 头像 -->
                    <!-- <el-avatar style="" shape="circle" :size="120" :src="item.imgurl" fit="cover"></el-avatar> -->
                    <el-image :src="item.imgurl" :fit="fits" class="avatar-box" ></el-image>
                    <!-- 基础信息 -->
                    <div class="infos">
                        <p style="font-weight: bold; font-size: 18px;">{{ item.name }}</p>
                        <p style="margin-top: -5px; font-size: 15px;">{{ item.major }}</p>
                        <p style="margin-top: -5px; font-size: 15px;">计算机科学与技术</p>
                    </div>
                </div>

            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fits: "cover",
            squareUrl:
                "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            students: [
                {
                    imgurl: "../../static/image/students/undergraduate/张丁文.jpg",
                    name: "张丁文",
                    major: "KG，GNN，LLM",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/洪晖阳.jpg",
                    name: "洪晖阳",
                    major: "靶向小分子生成",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/邹子昂.jpg",
                    name: "邹子昂",
                    major: "基础视觉模型",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/李疏影.jpg",
                    name: "李疏影",
                    major: "学习中",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/李赛博.jpg",
                    name: "李赛博",
                    major: "细粒度学习",

                },
                
            ]
        };
    },
}
</script>
<style scoped>
.container {
    margin-left: -20px;
}

.infos {
    margin-left: 12px;
    margin-top: 5px;
}

.infoBox {
    margin-top: 20px;
    display: flex;
}
.avatar-box{
    height: 130px;
    width: 130px;
    border-radius: 50%;
}
</style>