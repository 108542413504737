<template>
   <div>
      <!-- 期刊列表 -->
      <div class="experience-box">
         <div class="experience-infobox">

            <ul>
               <li v-for="item in journals" :key="itemIndex" class="infos">
                  <span style="font-size: 16px;">{{ item }}</span>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   name: 'Journals',
   data() {
      return {
         journals:[
            "Nature","Science","Innovation","National Science Review(NSR)","Science Bulletin",
            "Exploration","Proc. Natl. Acad. Sci.(PNAS)","Research","Engineering","Journal of Advanced Research",
            "Proceedings of the IEEE","Communications of the ACM","T. Pattern Analysis and Machine Intelligence(TPAMI)",
            "International Journal of Computer Vision(IJCV)","T. Neural Networks and Learning Systems(TNNLS)",
            "Molecular Plant","Trends in Plant Science","Plant Cell","Plant Communications",
            "Plant Physiology","Artificial Intelligence in Agriculture","Angewandte Chemie International Edition(Angew)",
            
         ]
      }
   }
}
</script>
<style scoped>
ul {
    list-style-type: none;
}

.infos {
    margin-top: 6px;
    
}
</style>