import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import ResarchDirection from '../view/ResearchDirection.vue'
import contents from '../view/contents.vue'
import Members from '../components/Members.vue'
import searchDirection from '../components/searchDirection.vue'
import PaperPatent from '../components/PaperPatent.vue'
import Awards from '../components/Awards.vue'
import Blogrolls from '../components/Blogrolls.vue'
import Projects from '../components/Projects.vue'
import Direction1 from '../components/Direction1.vue'
import Direction2 from '../components/Direction2.vue'
import TeacherWangQi from '../components/TeacherWangQi.vue'
import TeacherLiYuQuan from '../components/TeacherLiYuQuan.vue'
import Direction3 from '../components/Direction3.vue'
import MasterStudents from '../components/MasterStudents.vue'
import UndergraduateStudents from '../components/UndergraduateStudents.vue'
import InternationalStudents from '../components/InternationalStudents.vue'
import DocjtoralStudents from '../components/DocjtoralStudents.vue'
import TeacherXiaoYuanYuan from '../components/TeacherXiaoYuanYuan.vue'
import TeacherChenPanFeng from '../components/TeacherChenPanFeng.vue'
import TeacherYuPeiJia from '../components/TeacherYuPeiJia.vue'
import Journals from '../components/Journals.vue'
import CooperativeGroup from '../components/CooperativeGroup.vue'
import NationalAward from '../components/NationalAward.vue'
import HomeView from '../view/HomeView.vue'
import Learnmore from '../components/Learnmore.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomeView',
      component: HomeView
    },
    {
      path: '/ReDirec',
      name: 'ResarchDirection',
      component: ResarchDirection
    },
    {
      path: '/LearnMore',
      name: 'LearnMore',
      component: Learnmore
    },
    {
      path: '/MainContent',
      name: 'MainContent',
      component: contents,
      children: [
        {
          path: '/Members',
          name: 'Members',
          component: Members,
          children: [
            {
              path: '/WangQi',
              name: 'WangQi',
              component: TeacherWangQi
            },
            {
              path: '/LiYuQuan',
              name: 'LiYuQuan',
              component: TeacherLiYuQuan
            },
            {
              path: '/XiaoYuanYuan',
              name: 'XiaoYuanYuan',
              component: TeacherXiaoYuanYuan
            },
            {
              path: '/ChenPanFeng',
              name: 'ChenPanFeng',
              component: TeacherChenPanFeng
            },
            {
              path: '/YuPeiJia',
              name: 'YuPeiJia',
              component: TeacherYuPeiJia
            },
            {
              path: '/MasterStudents',
              name: 'MasterStudents',
              component: MasterStudents
            },
            {
              path: '/UndergraduateStudents',
              name: 'UndergraduateStudents',
              component: UndergraduateStudents
            },
            {
              path: '/InternationalStudents',
              name: 'InternationalStudents',
              component: InternationalStudents
            },
            {
              path: '/DocjtoralStudents',
              name: 'DocjtoralStudents',
              component: DocjtoralStudents
            }
          ]
        },
        {
          path: '/ResearchDirection',
          name: 'searchDirection',
          component: searchDirection,
          children: [
            {
              path: '/Direction1',
              name: 'Direction1',
              component: Direction1
            },
            {
              path: '/Direction2',
              name: 'Direction2',
              component: Direction2
            },
            {
              path: '/Direction3',
              name: 'Direction3',
              component: Direction3
            }

          ]
        },
        {
          path: '/PaperPatent',
          name: 'PaperPatent',
          component: PaperPatent
        },
        {
          path: '/Awards',
          name: 'Awards',
          component: Awards,
          children:[
            {
              path: '/NationalAward',
              name: 'NationalAward',
              component: NationalAward
            }
          ]
        },
        {
          path: '/Blogrolls',
          name: 'Blogrolls',
          component: Blogrolls,
          children: [
            {
              path: '/Journals',
              name: 'Journals',
              component: Journals
            },
            {
              path: '/CooperativeGroup',
              name: 'CooperativeGroup',
              component: CooperativeGroup
            }
          ]
        },
        {
          path: '/Projects',
          name: 'Projects',
          component: Projects
        },

      ]
    }
  ]
})
