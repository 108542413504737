<template>
    <div class="Doctor">
        <div v-for="i in docters" :key="i">
            <!-- 头像和基本信息 -->
            <div class="docter-baseinfo">
                <div>
                    <el-image style="height: 200px; width: 166px;" :src="i.imgURl"></el-image>
                </div>
                <div class="docter-baseinfo-infobox">
                    <div class="title-box1">
                        <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                            src="../../static/image/icon.png"></el-image>
                        <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                    </div>
                    <!-- 基本信息 -->
                    <div class="docter-baseinfo-content">
                        <ul>
                            <li class="infoss">
                                <span>{{ i.nema }}</span>
                            </li>
                            <!-- 个人简介 -->
                            <li class="profile-box">{{ i.profile }}</li>
                        </ul>
                    </div>
                    <!-- 重要学术奖项 -->
                    <div>

                    </div>

                </div>
            </div>
            <!-- 代表论文 -->
            <div class="paper-box">
                <div class="experience-infobox">
                    <div class="title-box3">
                        <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                            src="../../static/image/icon.png"></el-image>
                        <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                    </div>
                    <ul style="list-style-type: disc;">
                        <li v-for="item in i.papers" :key="item.id" class="infos">
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            imageUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            docters: [
                {
                    imgURl: '../../static/image/students/doctor/吴兴财.jpg',
                    nema: '吴兴财',
                    profile: '人工智能研究方向，已发表10篇论文，其中以第一作者发表一篇SCI一区论文和一篇计算机顶会论文。发明专利6项。获得博士国家奖学金、硕士国家奖学金、多项学业奖学金，国家级及以上竞赛奖3项，省级3项，校级3项。',
                    award: [],
                    papers: [
                        {value:"Xingcai Wu, Kejun Zhao et al. MISL: Multi-grained image-text semantic learning for text-guided image inpainting. Pattern Recognition.（第一作者，SCI一区top，2024）"},
                        {value:"Xingcai Wu, Xie Yucheng et al. Adversarial Learning with Mask Reconstruction for Text-Guided Image Inpainting. Proceedings of the 29th ACM International Conference on Multimedia.（第一作者，CCF A类会议，2021）"},
                        {value:"Qianding Huang, Xingcai Wu et al. Knowledge Distillation Facilitates the Lightweight and Efficient of Plant Diseases Detection Model. Plant Phenomics.（共同一作，SCI一区top，2023）"},
                        {value:"Xinyu Dong, Kejun Zhao, Qi Wang, Xingcai Wu et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science. Nucleic Acids Research.（共同一作，SCI二区，2023）"},
                        {value:"Kejun Zhao，Xingcai Wu et al. PlanText: Gradually Masked Guidance to Align Image Phenotype with Trait Description for Plant Disease Texts. Plant Phenomics. （第二作者，SCI一区top，2023）"},
                    ]
                },

            ]
        }
    }
}
</script>
<style>
.docter-baseinfo {
    margin-top: 10px;
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.award-box {
    position: relative;
    top: 20px;
}

.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 10px;
    width: 80%;
    text-indent: 1em;
    line-height: 25px;
}



.docter-baseinfo-content {
    margin-top: -25px;
    width: 100%;
    margin-left: -15px;

}

.infoss {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
}

ul {
    list-style-type: none;
}

.title-box1 {
    display: flex;
    width: 100%;
}

.docter-baseinfo-infobox {
    position: relative;
    left: 33px;
}
</style>