<template>
    <div class="teacher">
        <!-- 头像和基本信息 -->
        <div class="teacher-baseinfo">
            <div>
                <el-image style="height: 200px; width: 166px;" :src="imageUrl"></el-image>
            </div>
            <div class="teacher-baseinfo-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                </div>
                <!-- 基本信息 -->
                <div class="teacher-baseinfo-content">
                    <ul>
                        <li v-for="item in basicInfo" :key="item.id" class="infos">
                            <span>{{ item.label }}</span>
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 个人简介 -->
        <div class="profile-box">
            <p>{{ profile }}</p>
        </div>
        <!-- 学习工作经历 -->
        <div class="experience-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">学习工作经历</h2>
                </div>
                <ul>
                    <li v-for="item in experiment" :key="item.id" class="infos">
                        <span>{{ item.label }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 代表论文 -->
        <div class="paper-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                </div>
                <ul style="list-style-type: disc;">
                    <li v-for="item in papers" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 科研项目 -->
        <div class="project-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">科研项目</h2>
                </div>
                <ol >
                    <li v-for="item in project" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
        <!-- 重要奖项 -->
        <div class="award-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
                </div>
                <ol >
                    <li v-for="item in award" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TeacherWangQi',
    data() {
        return {
            imageUrl: '../../static/image/teacher/WangQi.png',
            // 信息数据
            basicInfo: [
                { label: "单位和职称：", value: "贵州大学公共大数据国家重点实验室特聘教授" },
                { label: "办公地址：", value: "贵州省贵阳市花溪区贵州大学西校区崇厚楼" },
                { label: "主要研究方向：", value: "人工智能、计算机视觉、多模态理解和分析、智慧农业、大数据分析和理解、三维方向、植物病虫害多模态数据分析、分子生成和属性预测、多/高光谱方向" },
                { label: "电子邮箱：", value: "qiwang@gzu.edu.cn" },
            ],
            experiment: [
                { label: "2021.03 ~ 现在：", value: "贵州大学，公共大数据国家重点实验室，特聘教授" },
                { label: "2023.07 ~ 2024.07：", value: "清华大学访问学者，导师：刘永进" },
                { label: "2018.07 ~ 2021.02：", value: "贵州大学，公共大数据国家重点实验室，特聘教授" },
                { label: "2019.01 ~ 2019.04：", value: "京东人工智能研究院，导师：梅涛, 刘武" },
                { label: "2015.09 ~ 2020.12：", value: "国内博士，广东工业大学，导师：刘文印" },
            ],
            papers: [
                { value: "Qi Wang, et al. MetaSearch: Incremental Product Search via Deep Meta-learning. IEEE Transactions on Image Processing, 2020, 29: 7549-7564. (第一作者, IF: 11.041, SCI 1, CCF A, Top)" },
                { value: "Xinyu Dong#, Kejun Zhao#, Qi Wang#*, et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science. Nucleic Acids Research. 2023.10. (共一和通讯, IF: 14.9, SCI 2, Top)" },
                { value: "Jinxiang Zhu, Qi Wang*, et al. FSNA: Few-Shot Object Detection via Neighborhood Information Adaption and All Attention. IEEE Transactions on Circuits and Systems for Video Technology, 2024. (通讯作者, IF: 8.4, CCF B, SCI 1, Top)" },
                { value: "Xinyu Dong, Qi Wang*, et al. PDDD-PreTrain: A series of common-used pre-trained models support image-based plant disease diagnosis. Plant Phenomics, 2023, 5:0054. (通讯作者, IF: 6.961, SCI 1, Top)" },
                { value: "Qi Wang, et al. AA-Trans: Core Attention Aggregating Transformer with Information Entropy Selector for Fine-grained Visual Classification. Pattern Recognition, 2023, 140:109547. (第一作者, IF: 8.518, SCI 1, CCF B, Top)" }

            ],
            project: [
                { value:"王崎，贵州省科技重大专项，2024.06-2027.05，智慧果园关键技术研究及装备研发，黔科合重大专项字[2024]002，主持课题二，在研"},
                { value:"王崎，贵州省科技创新团队，2023.12.01-2026.12.01，贵州省山地农作物病害智能感知与农药精准化利用科技创新人才团队，核心骨干成员（第二负责人），在研"},
                { value:"贵阳贵安科技人才培养项目，2024.01-2026.12，复杂环境下农业病虫害的多模态精准鉴定技术研究，主持"},
                { value:"贵州大学科研创新团队项目，2024.01-2026.12，多源异构作物病害大数据融合与应用科研创新团队，贵大科创团[2023]08号，主持。"},
                { value:"国家自然科学基金, 图像大数据的离散化表征理论和研究, 2022.01-2025.12，主持。"},
                { value:"贵州省高层次留学人才创新创业项目，农业视觉在植物病害检测的研究与应用，2023.04-2025.04，主持。"},
                { value:"贵州省科技厅自然科学基金，基于茶树树叶图像病害的细粒度识别研究，2022.04-2025.04，主持。"},
                { value:"贵州大学培育类项目，基于植物病害图像的大数据理论研究，2021.09-2024.9，主持。"},
                { value:"贵州大学特岗项目，少样本细粒度商品识别研究, 2021.06-2024.05，主持。"},

            ],
            award:[
                { value:"2024中国国际大数据博览会十大科技领先成果奖"},
                { value:"第二届全国博士后创新创业大赛，1项国家级银奖"},
                { value:"第十八届“挑战杯”全国大学生课外学术科技作品竞赛，1项国家级特等奖"},
                { value:"第十四届蓝桥杯全国软件和信息技术专业人才大赛， 2023.06，1项省赛一等奖，1项国家级三等奖"},
                { value:"2022年大学生创新创业训练计划项目，2022.11，国家级立项"},
                { value:"2023中国大学生工程实践与创新能力大赛省赛，2023.11，贵州省特等奖及国家级银奖"},
                { value:"2023“华为杯”中国研究生数学建模竞赛，2023.11，2项国家级三等奖"},
                { value:"第十八届“挑战杯”全国大学生课外学术科技作品竞赛，2023.03，2项省赛一等奖"},
            ],
            profile: "王崎（个人详情：https://qiwang.me/），特聘教授，硕士生导师，目前就职于贵州大学公共大数据国家重点实验室，清华大学访问学者，获得贵州省科技拔尖人才项目、贵州省科协青年托举人才项目，贵州省高层次留学人才项目，贵阳贵安科技人才项目，贵州大学科研创新团队负责人，广东工业大学校外导师，贵州省大数据局专家库专家，贵州省人才发展研究所人工智能库专家，《贵州省劳动用工大数据综合服务平台》技术顾问。博士在读期间曾在京东人工智能研究院担任算法工程师，而后成为广东省和比利时林堡省在国际教育合作方面的第一位联合培养博士研究生，并于2020年12月获得国内工学博士学位，2021年2月获得国外工程技术博士学位，是广东工业大学第一位获得双博士学位的毕业生。长期从事计算机视觉和智慧农业的相关研究，截至至今作为第一或者通讯作者发表SCI论文29篇，其中CCF A类和SCI 1区论文共计19篇，所发表的学术期刊论文累计影响因子（IF）>150，主持承担了1项国家自然科学基金，以及3项省部级课题，曾多次获得了国际国内的知名科研竞赛奖项，获11项中国发明专利授权。指导本科生发表SCI 1区论文并获国家级大学生创新创业项目1项，获得全国大学生挑战杯（大挑）全国特等奖以及若干省级校级竞赛奖励。此外，担任Plant Phenomics期刊青年编委，20多个国际顶级会议和期刊的审稿人，如TCYB, TNNLS, TMM, TKDD, ACMMM, Plant Phenomics等等。主持并完成了一项比利时国际双边科学合作项目，为中比双方科研合作奠定了良好的基础。"
        }
    }
}
</script>

<style>
.award-box{
    position: relative;
    top: 20px;
}
.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    text-indent: 2em;
    line-height: 25px
}

.teacher-baseinfo-content {
    width: 100%;
}

.infos {
    margin-top: 10px;
}

ul {
    list-style-type: none;
}

.title-box3 {
    display: flex;
    width: 100%;
}

.teacher-baseinfo-infobox {
    position: relative;
    left: 33px;
}

.teacher-baseinfo {
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.teacher {
    width: 89%;
}
</style>