<template>
    <div class="page-container">
    <!-- 顶部导航 -->
    <el-header class="header">
      <div class="logo"><img src="../../static/image/logo.png" class="logo_img">
        <div class="logo_text">实验室课题组</div>
      </div>
      <div class="nav-box">
        <el-menu :default-active="activeMenu" class="nav-menu" mode="horizontal" router>
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="/ResearchDirection">研究方向</el-menu-item>
          <el-menu-item index="/Members">人员组成</el-menu-item>
          <el-menu-item index="/PaperPatent">成果发表</el-menu-item>
          <el-menu-item index="/Projects">开源服务</el-menu-item>
          <el-menu-item index="/Awards">获奖展示</el-menu-item>
          <el-menu-item index="/Blogrolls">友情链接</el-menu-item>
        </el-menu>
      </div>

    </el-header>
   
  
      <!-- Banner 区域 -->
      <section class="banner">
        <div class="title1">
        <h3>欢迎大家的加入</h3>
    </div>
        <div class="contact-info1">
       <h2>感兴趣请联系邮箱：wangqi@zju.edu.cn</h2>
    </div>
      </section>
  

  
      <!-- 加入我们 -->
    <section class="join-us">
    <div class="content-with-image">
      <div class="text-content">
        <h1>1, 招聘全职老师</h1>
       
        <p>我们渴望遇见那些心怀梦想、志存高远的教育者。您不仅是一位学者，更是一位导师，引领学生在知识的海洋中遨游，激发他们的好奇心与创造力。您的加入将为我们的团队注入新的活力，共同打造一个充满激情与创新的学习环境。</p>
        
      </div>
   
    </div>
  </section>
     <!-- 加入我们 -->
     <section class="join-us">
    <div class="content-with-image">
      <div class="text-content">
        <h1>2, 招聘博士后或访问学者</h1>
        
        <p>对于那些已经在学术领域取得一定成就的研究者，我们欢迎您加入我们的研究团队。在这里，您将有机会深入探索前沿课题，与国内外顶尖学者交流合作，共同推动科学研究的发展。我们提供开放的研究平台和丰富的资源支持，期待与您共创辉煌。</p>
        
      </div>
     
    </div>
  </section>
     <!-- 加入我们 -->
     <section class="join-us">
    <div class="content-with-image">
      <div class="text-content">
        <h1>3, 招收博士生或联培生</h1>
   
        <p>如果您对科研充满热情，渴望在学术道路上走得更远，那么我们欢迎您加入我们的博士生或联合培养项目。在这里，您将接受系统的科研训练，参与到高水平的科研项目中，与导师和团队成员一起攻克难关，实现个人的学术梦想。</p>
        
      </div>
  
    </div>
  </section>
     <!-- 加入我们 -->
     <section class="join-us">
    <div class="content-with-image">
      <div class="text-content">
        <h1> 4, 招收硕士生</h1>
       
        <p>对于那些对科研抱有浓厚兴趣的硕士生，我们为您提供一个良好的学习和发展平台。在这里，您将有机会参与到实际的科研项目中，与团队成员共同合作，提升自己的研究能力和实践技能。我们鼓励创新思维，支持每位学生在学术道路上不断前行。</p>
        
      </div>
     
    </div>
  </section>
     <!-- 加入我们 -->
     <section class="join-us">
    <div class="content-with-image">
      <div class="text-content">
        <h1>5, 招收本科生</h1>
      
        <p>如果您是一名本科生，对科学研究充满好奇和热情，欢迎加入我们的团队。在这里，您将获得宝贵的科研经验，参与到实际项目中，与导师和团队成员密切合作。我们相信，每一个热爱科学的年轻人，都有机会在这里找到属于自己的舞台。</p>
        
      </div>
  
    </div>
  </section>
  
  
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeMenu: "1",
      };
    },
  };
  </script>
  
  <style scoped>
  .page-container {
    font-family: Arial, sans-serif;
    color: #333;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    background-color: rgba(255, 255, 255, 0);
  /* 半透明背景 */
    position: absolute;
   /* 使导航栏固定在顶部 */
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    /* 确保在图片之上 */
   border: none;
   box-shadow: none;
   outline: none;
  }
  
  .logo{
    padding-left: 10%;
    display: flex;
    align-items: center; /* 垂直居中 */
  }
  
  .logo_text {
    margin-left: 28px; /* 调整此值以增加或减少图片和文字之间的距离 */
    font-family: STHeiti;
    font-size: 25px;
    font-weight: bold;
  
  }
  
  .nav-menu {
   flex: 1;
   text-align: left;
   align-items: flex-end;
   background-color: rgba(241, 231, 231, 0);
   /* 半透明背景 */
   border: none;
   box-shadow: none;
   outline: none;
   /* 去掉菜单的边框 */
   
   
  
  }
  .nav-box {
    margin-right: 200px;
    font-size: 50xp;
    border: 0px;
  }
  .nav-menu .el-menu-item {
    background-color: rgb(0 0 0 / 0%);
    /* 菜单项背景透明 */
    color: black;
    /* 文字颜色，根据背景调整 */
    border: 0px;
  }
  
  .nav-menu .el-menu-item.is-active {
    color: #2448FF;
    /* 激活项颜色 */
    background-color: rgb(0 0 0 / 0%);
    /* 菜单项背景透明 */
    border: 0px;
  }
  
  .banner {
    text-align: center;
   
     /* 使图片等比例填充 */
  background-repeat: no-repeat; /* 防止图片重复 */
  background-position: center; /* 图片居中显示 */
  padding: 100px;
  background-color:#e9f6fc
  }
  .banner h3{
    font-size: 75px;
    margin-left: 100px;
    color:  #2448FF;
  }
  .banner h2{
    
    font-size: 30px;
    margin-left: 900px;
    position: relative;
  top: 50px; /* 将文本向下移动 50px */
  }

.contact-info1 {
  margin-bottom: 20px; /* 控制底部间距 */
}
  .container {
    display: flex;
    flex-direction: column;
    min-height: 10vh;
  }
  
  .join-us {
    background-color: #fafafa; 
    text-align: center;
    margin: 40px 0;
    text-align: left;
    padding-left: 15%;
    
  }
  .join-us h1{
    font-size: 40px;
    margin-top: 70px;
  }

  .join-us p{
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 30px;
  }
  .content-with-image {
      display: flex;
      align-items: center; /* 垂直居中对齐 */
    }
  
    .text-content {
      flex: 1; /* 文字内容区域占据剩余空间 */
    }
  
    .image-content {
      margin-right: 270px; /* 图片与文字之间的间距 */
      margin-top: 20px;
    }
  
    .image-content img {
      max-width: 90%; /* 图片最大宽度为父元素宽度 */
      height: auto; /* 保持图片原始比例 */
    }
  .learn-more-button {
    margin-top: 20px;
  }
  
  .contact-detailscontainer {
    display: flex;
    justify-content: space-between;
  
    text-align: left;
    padding-left: 15%;
    background-color: #fafafa;
    padding: 20px;
  }
  .contact-details {
    padding-left: 13%;
  }
  .contact-info h1{
    background-color: #fafafa;
    padding: 20px;
    font-size: 35px;
    margin-top: 70px;
    
  }
  .contact-info h2{
    background-color: #fafafa;
    padding: 20px;
    font-size: 40px;
    margin-top: 40px;
    opacity: 0.1;
   
    
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    padding: 30px;
    
  }
  
  .contact-text{
    margin-left: 28px;
  }
  
  
  .map-image img{
      margin-right: 150px; /* 图片与文字之间的间距 */
      margin-top: 10px;
      width: 800px; /* 调整图片的宽度 */
      height: 700px; /* 调整图片的高度 */
    }
  
  .banner h1 ,p{
    text-align: left;
    padding-right: 30%;
    /* margin-left: 15%; */
  }
  .banner h1{
    font-size: 3rem;
    color:#4765fe
  }
  .banner p{
    font-size: 1.9rem;
  }
  
  
  .grid-container {
     display: grid;
  
      grid-template-columns: repeat(2, 1fr); /* 2 列，每列等宽 */
      grid-template-rows: repeat(2, 1fr); /* 2 行，每行等高 */
      gap: 0px; /* 网格项之间的间隔 */
      width: 100%; /* 宽度可以根据需要调整 */
      max-width: 600px; /* 最大宽度 */
      background-color: #fff;
      /* padding: 10px; */
      box-sizing: border-box;
  
  
  }
  
  .grid-item {
      /* height: 100px; */
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
  }
  .grid-item h3{
    color: #4765fe;
  }
  </style>
  