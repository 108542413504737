<template>
    <div class="searchDirection">
        <el-container>
            <el-aside width="220px">
                <!-- 标题名和那两个小框框 -->
                <div class="title-box">
                    <div class="boxs">
                        <div class="solid-box1"></div>
                        <div class="solid-box2"></div>
                    </div>
                    <div class="aside-title">
                        <p style="font-size: 26px; margin-top: 30px;width: 150px;">{{ webTitle }}</p>
                    </div>
                </div>
                <!-- 主要的导航栏 -->
                <div class="main-navbar">
                    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="vertical" @select="handleSelect"
                        active-text-color="#2448FF">
                        <el-menu-item v-for="item in contents" :key="item.path" :index="item.path">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu>
                </div>
            </el-aside>

            <!-- 主要内容 -->

            <el-main class="main-content">
                <!-- 标题块 -->

                <div style="width: 90%;">
                    <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
                    <div class="line"></div>
                    <div class="nameBox">
                        <div class="numBox">
                            <h3>1</h3>
                        </div>
                        <div class="titleBox">
                            <h3 style="position: relative;left: 23px;">{{ ContentName }}</h3>
                        </div>
                    </div>
                </div>
                <!-- 主要的内容块 -->
                <div class="infoBox">
                    <router-view></router-view>

                </div>
            </el-main>
        </el-container>

    </div>
</template>

<script>
export default {
    data() {
        return {
            webTitle: "成果发表",
            TileName: 'Publish Results',
            activeIndex: '1',
            ContentName: '论文著作',
            contents: [
                { name: '论文著作', path: '1' },
                { name: '发明专利', path: '2' },
            ]
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            // 根据 key 找到对应的成员并更新 TeacherName
            const selectedContent = this.contents.find((content) => content.path === key);
            if (selectedContent) {
                this.ContentName = selectedContent.name; // 更新 TeacherName
            }
            console.log(`Selected: ${key}, ContentName: ${this.ContentName}`);
        }
    }
}

</script>

<style scoped>
.numBox {
    width: 67px;
    height: 67px;
    background-color: #D4DFEB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #2448FF;

}

.titleBox {
    height: 67px;
    width: 100%;
    font-size: 17px;
    color: rgb(45, 55, 90);
    background-color: #F7F9FA;
    justify-content: center;
    align-items: center;

}

.nameBox {
    display: flex;
    position: relative;
    top: 49px;

}

.infoBox {
    position: relative;
    top: 49px;
    left: 2%;
}

.line {
    position: relative;
    top: 30px;
    bottom: 32.5px;
    border: 0.1px solid #BCBCBC;
    width: 100%;
}

.main-content {
    position: relative;
    left: 4%;
    overflow: visible;
    /* 禁止自身滚动条 */

}

.searchDirection {
    /* width: 100%; */
    display: flex;
    height: 100%;
}

.title-box {
    height: 107px;
    background-color: white;
    width: 220px;
}

.el-aside {
    background-color: #F1F4F7;
    height: 100%;
    color: #333;
    text-align: center;
    overflow: hidden;

}

.aside-title {
    background-color: white;
    width: 150px;
    text-align: left;
    position: relative;
    left: 36px;
}

.boxs {
    display: flex;
    position: relative;
    top: 30px;
    left: 36px;
}

.solid-box1 {
    width: 25px;
    height: 25px;
    background-color: #2448FF;


}

.solid-box2 {
    width: 25px;
    height: 25px;
    background-color: #2448FF;
    opacity: 0.21;
    position: relative;
    left: 4px;
}

.main-navbar {
    width: 220px;
    height: 80%;
    background-color: #F1F4F7;
}

.el-menu-demo {
    text-align: left;
    background-color: #F1F4F7;
    position: relative;
}

.el-menu-item {
    height: 50px;
    width: 220px;
    /* margin-left: 20px; */
    font-size: 16px;
}

.el-menu-item.is-active {
    background-color: #dfecf8 !important;
}
</style>