<template>
    <div class="direction1">
        <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
        <div class="line"></div>
        <!-- 标题块  -->
        <div class="nameBox">
            <div class="numBox">
                <h3>1</h3>
            </div>
            <div class="titleBox">
                <h3 style="position: relative;left: 23px;">人工智能分子设计</h3>
            </div>
        </div>
        <!-- 内容块 -->
        <div>
            <div class="contentBox">
                <p>人工智能分子设计是一个跨领域的前沿研究方向，通过将语言大模型、图学习等前沿人工智能算法算法与生命科学深度融合，致力于解决传统分子设计中效率低、成本高、突破性创新难等关键挑战。这一技术在小分子化合物和生物大分子两大领域都展现出革命性潜力，并在多个应用场景中发挥重要作用，正在重塑多个重要应用领域的研发模式。
                </p>
                <p>在小分子化合物设计方面，人工智能算法可以在庞大的化学空间中进行智能搜索和优化。通过深度生成模型（如VAE、GAN等），结合图神经网络（GNN）的分子表征方法，能够设计具有目标性能的全新分子结构。这一技术已在多个领域取得突破：在医药研发中可设计高活性低毒性的候选药物；在农业领域可开发环境友好型农药及植物生长调节剂；在新材料研发中可设计具有特定光电、催化等性能的功能分子。

                </p>
                <p>生物大分子设计领域同样展现出巨大潜力。借助AlphaFold等突破性算法，研究人员可以精准预测和设计蛋白质结构。这不仅应用于治疗性蛋白质药物的开发，还广泛用于工业酶的设计优化。通过深度学习模型理解序列-结构-功能关系，可以设计具有更高活性、更好稳定性的工业酶，应用于生物催化、环境治理等领域。同时，这一技术也在功能性生物材料（如生物降解材料、生物传感材料等）的开发中发挥重要作用。
                </p>
                <p>
                    多目标优化是该领域的核心技术之一。研究人员可以同时考虑分子的多个性能指标，如活性、选择性、稳定性、成本等，实现综合性能的最优化。可解释性AI技术的引入使得分子设计过程更加透明可控，有助于理解构效关系并指导理性设计。此外，通过迁移学习等技术，可以充分利用跨领域的分子数据，提升设计效率。
                </p>
                <p>
                    未来，随着计算能力的提升、实验数据的积累以及算法的进步，人工智能分子设计将在更多领域发挥重要作用。这一技术将推动新一代药物、绿色农药、清洁能源材料、工业催化剂等的开发，为解决人类面临的健康、环境、能源等重大挑战提供强有力的技术支撑。
                </p>
            </div>

            <el-image class="imageBox" style="width: 90%;" src="../../static/image/Direction3.png" fit="cover" />
        </div>


    </div>

</template>



<script>
export default {
    data() {
        return {
            TileName: 'Research Direction',
            researchItems: [
                { id: 1, title: "人工智能分子设计" }
            ]
        }
    }
}
</script>
<style scoped>
.imageBox {
    position: relative;
    top: 60px;
    left: 5%;
}

.contentBox {
    position: relative;
    top: 60px;

}

.contentBox p {
    font-weight: 500;
    text-align: left;
    text-indent: 2em;
    padding: 0px;
    margin: 0px;
    line-height: 30px;

}

.nameBox {
    display: flex;
    position: relative;
    top: 49px;

}

.direction1 {
    width: 90%;
}

.line {
    position: relative;
    top: 30px;
    bottom: 32.5px;
    border: 0.1px solid #BCBCBC;
    width: 100%;
}

.numBox {
    width: 67px;
    height: 67px;
    background-color: #D4DFEB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #2448FF;

}

.titleBox {
    height: 67px;
    width: 100%;
    font-size: 17px;
    color: rgb(45, 55, 90);
    background-color: #F7F9FA;
    justify-content: center;
    align-items: center;

}
</style>